/**
 * @flow
 * @relayHash 2b6fdd7ffb6b5ac2a0fd4b0e9688bd82
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
import type { AudioDeviceRegistration_viewer$ref } from "./AudioDeviceRegistration_viewer.graphql";
import type { AudioDevices_viewer$ref } from "./AudioDevices_viewer.graphql";
import type { Notifications_viewer$ref } from "./Notifications_viewer.graphql";
import type { PlaybackTabsQuery_viewer$ref } from "./PlaybackTabsQuery_viewer.graphql";
import type { Tags_viewer$ref } from "./Tags_viewer.graphql";
import type { UserSettings_viewer$ref } from "./UserSettings_viewer.graphql";
import type { Users_viewer$ref } from "./Users_viewer.graphql";
import type { WebkeyAppManagement_viewer$ref } from "./WebkeyAppManagement_viewer.graphql";
import type { WebkeyDashboard_viewer$ref } from "./WebkeyDashboard_viewer.graphql";
import type { WebkeyTasks_viewer$ref } from "./WebkeyTasks_viewer.graphql";
export type _deviceStatusType = "ACTIVE" | "INACTIVE" | "REGISTERED" | "UNREGISTERED" | "%future added value";
export type _statusType = "COMPLETED" | "RECEIVED" | "SENT" | "%future added value";
export type typeRoleEnum = "access" | "audio" | "video" | "%future added value";
export type miniDrawerQueryVariables = {||};
export type miniDrawerQueryResponse = {|
  +viewer: {|
    +user: {|
      +id: string,
      +profile: ?{|
        +name: ?string,
        +clientId: ?string,
        +videoClientId: ?string,
      |},
      +consoleSettings: ?{|
        +audioDevices: ?{|
          +appDetails: ?boolean,
          +logs: ?boolean,
          +nowPlaying: ?boolean,
          +systemDetailsControls: ?boolean,
          +user: ?boolean,
          +tags: ?boolean,
        |},
        +videoDevices: ?{|
          +appDetails: ?boolean,
          +deviceActions: ?boolean,
          +systemDetailsControls: ?boolean,
          +tags: ?boolean,
        |},
        +users: ?{|
          +details: ?boolean,
          +actions: ?boolean,
          +permissions: ?boolean,
          +logs: ?boolean,
        |},
      |},
    |},
    +allNotifications: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: {|
          +id: string,
          +type: string,
          +message: string,
          +dateCreated: any,
          +seenBy: ?$ReadOnlyArray<?string>,
        |}
      |}>
    |},
    +allEvents: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: {|
          +id: string,
          +userId: ?string,
          +clientId: ?string,
          +methods: ?$ReadOnlyArray<?string>,
          +tag: ?string,
          +deviceUid: ?string,
          +date: ?any,
          +status: ?_statusType,
        |}
      |}>
    |},
    +allAudioDevices: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: {|
          +id: string,
          +deviceStatus: ?_deviceStatusType,
        |}
      |}>
    |},
    +allMethods: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: {|
          +id: string,
          +displayName: string,
          +methodName: string,
          +description: ?string,
          +UIElementsID: $ReadOnlyArray<?string>,
          +roleType: typeRoleEnum,
          +userType: $ReadOnlyArray<?number>,
        |}
      |}>
    |},
    +$fragmentRefs: Notifications_viewer$ref & UserSettings_viewer$ref & AudioDeviceRegistration_viewer$ref & WebkeyDashboard_viewer$ref & WebkeyAppManagement_viewer$ref & WebkeyTasks_viewer$ref & PlaybackTabsQuery_viewer$ref & AudioDevices_viewer$ref & Users_viewer$ref & Tags_viewer$ref,
  |}
|};
export type miniDrawerQuery = {|
  variables: miniDrawerQueryVariables,
  response: miniDrawerQueryResponse,
|};
*/


/*
query miniDrawerQuery {
  viewer {
    user {
      id
      profile {
        name
        clientId
        videoClientId
      }
      consoleSettings {
        audioDevices {
          appDetails
          logs
          nowPlaying
          systemDetailsControls
          user
          tags
        }
        videoDevices {
          appDetails
          deviceActions
          systemDetailsControls
          tags
        }
        users {
          details
          actions
          permissions
          logs
        }
      }
    }
    allNotifications {
      edges {
        node {
          id
          type
          message
          dateCreated
          seenBy
        }
      }
    }
    allEvents {
      edges {
        node {
          id
          userId
          clientId
          methods
          tag
          deviceUid
          date
          status
        }
      }
    }
    allAudioDevices {
      edges {
        node {
          id
          deviceStatus
        }
      }
    }
    allMethods {
      edges {
        node {
          id
          displayName
          methodName
          description
          UIElementsID
          roleType
          userType
        }
      }
    }
    ...Notifications_viewer
    ...UserSettings_viewer
    ...AudioDeviceRegistration_viewer
    ...WebkeyDashboard_viewer
    ...WebkeyAppManagement_viewer
    ...WebkeyTasks_viewer
    ...PlaybackTabsQuery_viewer
    ...AudioDevices_viewer
    ...Users_viewer
    ...Tags_viewer
  }
}

fragment AudioDeviceRegistration_viewer on Viewer {
  allTags {
    edges {
      node {
        id
        name
        description
      }
    }
  }
  allClients {
    edges {
      node {
        id
        name
      }
    }
  }
  allSchedule {
    edges {
      node {
        id
        name
        injectable
        clientId
        startDate
        endDate
      }
    }
  }
  allAudioDevices {
    edges {
      node {
        id
        name
        clientId
        deviceStatus
        wlan
        scheduleIds
        uid
        makeAndModel
        make
        appVersionDS
        publicIpAddress
        macAddress
      }
    }
  }
  allAudioDevicesOld {
    id
    name
    macAddress
  }
}

fragment AudioDevices_viewer on Viewer {
  allNPLogs {
    edges {
      node {
        id
        date
        deviceId
      }
    }
  }
  allPCLogs {
    edges {
      node {
        id
        date
        deviceId
      }
    }
  }
  allTags {
    edges {
      node {
        id
        name
        description
      }
    }
  }
  allClients {
    edges {
      node {
        id
        name
        playlistIds
        sceneIds
        logo
      }
    }
  }
  allSchedule {
    edges {
      node {
        id
        name
        injectable
        clientId
        startDate
        endDate
      }
    }
  }
  allPlaylists {
    edges {
      node {
        id
        name
        clientsId
      }
    }
  }
  allUsers {
    edges {
      node {
        id
        profile {
          name
          clientId
          clientName
        }
        emails {
          address
        }
      }
    }
  }
  allAudioDevices {
    edges {
      node {
        id
        isPlaying
        name
        appStatus {
          status
          message
          time
          description
        }
        lastSyncTime
        clientId
        appUpTime
        currentM3uName
        appVersion
        uid
        description
        volume
        remoteVolume
        remoteVolumeMode
        requestPCLogs
        requestNPLogs
        lastOnline
        currentPlaylistId
        currentSceneId
        currentSongId
        currentAlbum
        currentArtist
        currentArtwork
        remotePlaylistMode
        isOnline
        currentMd5
        currentSongFilename
        totalSdStorage
        usedSdStorage
        availableSdStorage
        m3uFilesOnDevice
        songFilesOnDevice
        latitude
        longitude
        skipCount
        skipDisabled
        likedSong
        scheduleIds
        usersId {
          id
          profile {
            name
            clientId
            clientName
          }
          emails {
            address
          }
        }
        playlistsId
        playbackIds
        tags
        enrollmentDate
        deviceStatus
        makeAndModel
        macAddress
        ethernetMacAddress
        internalIpAddress
        publicIpAddress
        webkeyId
        webkeyBackupId
        appVersionDS
        kpi1
        kpi3
        kpi30
        crossfade
        maximumDaysOffline
        maximumSongsOffline
        serialNumber
      }
    }
  }
  user {
    id
    consoleSettings {
      audioDevices {
        appDetails
        logs
        nowPlaying
        systemDetailsControls
        user
        tags
      }
    }
  }
  allScenes {
    edges {
      node {
        id
        name
        description
        image
        size
        createdAt
        playlists {
          playlistId
        }
      }
    }
  }
}

fragment Notifications_viewer on Viewer {
  allNotifications {
    edges {
      node {
        id
        type
        message
        dateCreated
        seenBy
      }
    }
  }
}

fragment PlaybackTabsQuery_viewer on Viewer {
  allClients {
    edges {
      node {
        id
        name
        playlistIds
        playbackIds
        sceneIds
        featuredIds
        logo
        preview {
          previewId
          created
          ending
          isEnded
          isEndless
        }
        viewableCategories {
          name
          subCategories
        }
        useExplicit
      }
    }
  }
  allPlaylists {
    edges {
      node {
        id
        name
        description
        cover
        tracks
        size
        failed
        updatedAt
        createdAt
        signedUrl
        genre
        subGenre
        tempo
        clientsId
        duration
        files {
          status
        }
      }
    }
  }
  allPlaybacks {
    edges {
      node {
        id
        name
        description
        shuffle
        image
        size
        createdAt
        updatedAt
        playlists {
          playlistId
          name
          nrOfSongs
          filename
        }
        clientId
      }
    }
  }
  allSchedule {
    edges {
      node {
        id
        name
        description
        size
        injectable
        startDate
        endDate
        clientId
        playingPlaylists {
          playlistId
          name
          volume
          startTime
          endTime
          startTimeSeconds
          endTimeSeconds
          days
          shuffle
          filenames {
            mixId
            nrOfSongs
          }
        }
      }
    }
  }
  allAudioDevices {
    edges {
      node {
        id
        name
        scheduleIds
        clientId
        deviceStatus
        currentPlaylistId
      }
    }
  }
  allCategories {
    edges {
      node {
        id
        name
        subCategories
        sortOrder
      }
    }
  }
  allScenes {
    edges {
      node {
        id
        name
        description
        image
        size
        createdAt
        updatedAt
        playlists {
          playlistId
        }
        tracks
        newCategory {
          category
          subCategories
        }
      }
    }
  }
}

fragment Tags_viewer on Viewer {
  allTags {
    edges {
      node {
        id
        name
        description
      }
    }
  }
  allVideoDevices {
    edges {
      node {
        id
        name
        tags
      }
    }
  }
  allAudioDevices {
    edges {
      node {
        id
        name
        tags
        deviceStatus
      }
    }
  }
}

fragment UserSettings_viewer on Viewer {
  user {
    id
    createdAt
    services {
      google {
        accessToken
        idToken
        id
        email
        name
        given_name
        family_name
        picture
      }
      facebook {
        accessToken
        id
        email
        name
        first_name
        last_name
        link
        gender
        locale
        verified
      }
    }
    emails {
      address
      verified
    }
    profile {
      name
      clientId
      videoClientId
    }
    status {
      online
      lastLogin {
        date
        ipAddr
        userAgent
      }
    }
    consoleSettings {
      audioDevices {
        appDetails
        logs
        nowPlaying
        systemDetailsControls
        user
        tags
      }
      videoDevices {
        appDetails
        deviceActions
        systemDetailsControls
        tags
      }
      users {
        details
        actions
        permissions
        logs
      }
    }
  }
}

fragment Users_viewer on Viewer {
  allClients {
    edges {
      node {
        id
        name
      }
    }
  }
  allVideoClients {
    edges {
      node {
        id
        name
      }
    }
  }
  allAudioDevices {
    edges {
      node {
        id
        isPlaying
        isOnline
        publicIpAddress
        name
        clientId
        usersId {
          id
        }
        deviceStatus
      }
    }
  }
  allUsers {
    edges {
      node {
        id
        roles
        createdAt
        apiKey
        services {
          password {
            bcrypt
          }
          google {
            accessToken
            idToken
            id
            email
            name
            given_name
            family_name
            picture
          }
          facebook {
            accessToken
            id
            email
            name
            first_name
            last_name
            link
            gender
            locale
            verified
          }
        }
        emails {
          address
          verified
        }
        profile {
          name
          clientId
          videoClientId
          sonosPermit
          qsysPermit
        }
        rolesConsole {
          roleAccess
          roleAudio
          roleVideo
        }
        sonos {
          songCount
          lastPlayedTimestamp
          extensionMenuId
        }
        qsys {
          pin
          multiTenant
          globalZoneLimit
        }
        serviceType
        defaultServiceType
        status {
          online
          lastLogin {
            date
            ipAddr
          }
        }
        companyName
        qsysData {
          qsysDeviceIds
          noOfCores
          noOfZones
        }
      }
    }
  }
  allSonosMenu {
    id
    name
    description
    clientId
    childrenMenus {
      id
      name
      description
    }
  }
  user {
    id
    consoleSettings {
      users {
        details
        actions
        permissions
        logs
      }
    }
    roles
    companyName
    rolesConsole {
      roleAccess
      roleAudio
      roleVideo
    }
  }
}

fragment WebkeyAppManagement_viewer on Viewer {
  allWebkeyDevices {
    edges {
      node {
        id
        DisplayName
        Serial
        DeviceNick
        DeviceBrand
        DeviceModel
        WebkeyVersion
        Location
        LastConnected
        AndroidAPI
        AndroidVersion
        Rooted
        Online
      }
    }
  }
  allAudioDevices {
    edges {
      node {
        id
        name
        macAddress
        ethernetMacAddress
        clientId
        webkeyId
      }
    }
  }
  allWebkeyPackages {
    edges {
      node {
        id
        appName
        keyName
        source
        packageName
        description
        versionCode
        versionName
        icon
      }
    }
  }
}

fragment WebkeyDashboard_viewer on Viewer {
  allWebkeyDevices {
    edges {
      node {
        id
        DisplayName
        Serial
        DeviceNick
        DeviceBrand
        DeviceModel
        WebkeyVersion
        Location
        LastConnected
        AndroidAPI
        AndroidVersion
        Rooted
        Online
        duplicate
      }
    }
  }
  allWebkeyDevicesBackup {
    edges {
      node {
        id
        DisplayName
        Serial
        DeviceNick
        DeviceBrand
        DeviceModel
        WebkeyVersion
        Location
        LastConnected
        AndroidAPI
        AndroidVersion
        Rooted
        Online
        duplicate
      }
    }
  }
  allAudioDevices {
    edges {
      node {
        id
        name
        macAddress
        ethernetMacAddress
        clientId
        webkeyId
        webkeyBackupId
        appVersion
        appVersionDS
        isOnline
        makeAndModel
      }
    }
  }
  allVideoDevices {
    edges {
      node {
        id
        name
        macAddress
        lanMacAddress
        clientId
        webkeyId
        webkeyBackupId
        appVersion
      }
    }
  }
  allWebkeyPackages {
    edges {
      node {
        id
        appName
        packageName
        keyName
        description
        source
        versionCode
        versionName
        icon
      }
    }
  }
  allWebkeyCustomActions {
    edges {
      node {
        id
        name
        actionCode
      }
    }
  }
  allVideoClients {
    edges {
      node {
        id
        name
      }
    }
  }
  allClients {
    edges {
      node {
        id
        name
      }
    }
  }
}

fragment WebkeyTasks_viewer on Viewer {
  allWebkeyDevices {
    edges {
      node {
        id
        DisplayName
        Serial
        DeviceNick
        DeviceBrand
        DeviceModel
        WebkeyVersion
        Location
        LastConnected
        AndroidAPI
        AndroidVersion
        Rooted
        Online
      }
    }
  }
  allWebkeyTasks {
    edges {
      node {
        id
        instance
        userId
        clientId
        packageId
        type
        reports {
          deviceid
          state
        }
        date
      }
    }
  }
  allAudioDevices {
    edges {
      node {
        id
        name
        webkeyId
        webkeyBackupId
      }
    }
  }
  allVideoDevices {
    edges {
      node {
        id
        name
        webkeyId
        webkeyBackupId
      }
    }
  }
  allWebkeyPackages {
    edges {
      node {
        id
        appName
        keyName
        source
        packageName
        description
        versionCode
        versionName
        icon
      }
    }
  }
  allWebkeyCustomActions {
    edges {
      node {
        id
        name
        actionCode
      }
    }
  }
  allUsers {
    edges {
      node {
        id
        emails {
          address
          verified
        }
        profile {
          name
          clientId
          videoClientId
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "videoClientId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "UserProfile",
  "kind": "LinkedField",
  "name": "profile",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "appDetails",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logs",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "systemDetailsControls",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tags",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "ConsoleSettings",
  "kind": "LinkedField",
  "name": "consoleSettings",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AudioDeviceSettings",
      "kind": "LinkedField",
      "name": "audioDevices",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nowPlaying",
          "storageKey": null
        },
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "user",
          "storageKey": null
        },
        (v8/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VideoDeviceSettings",
      "kind": "LinkedField",
      "name": "videoDevices",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deviceActions",
          "storageKey": null
        },
        (v7/*: any*/),
        (v8/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UsersSettings",
      "kind": "LinkedField",
      "name": "users",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "details",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "actions",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "permissions",
          "storageKey": null
        },
        (v6/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "NotificationConnection",
  "kind": "LinkedField",
  "name": "allNotifications",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "NotificationEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Notification",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v10/*: any*/),
            (v11/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dateCreated",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "seenBy",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "EventConnection",
  "kind": "LinkedField",
  "name": "allEvents",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EventEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Event",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v13/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "methods",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tag",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "deviceUid",
              "storageKey": null
            },
            (v14/*: any*/),
            (v15/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviceStatus",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "MethodConnection",
  "kind": "LinkedField",
  "name": "allMethods",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MethodEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Method",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "methodName",
              "storageKey": null
            },
            (v18/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "UIElementsID",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "roleType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "userType",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessToken",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "Google",
  "kind": "LinkedField",
  "name": "google",
  "plural": false,
  "selections": [
    (v21/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "idToken",
      "storageKey": null
    },
    (v0/*: any*/),
    (v22/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "given_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "family_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "picture",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "verified",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "Facebook",
  "kind": "LinkedField",
  "name": "facebook",
  "plural": false,
  "selections": [
    (v21/*: any*/),
    (v0/*: any*/),
    (v22/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "first_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "link",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gender",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "locale",
      "storageKey": null
    },
    (v24/*: any*/)
  ],
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "concreteType": "UserEmails",
  "kind": "LinkedField",
  "name": "emails",
  "plural": true,
  "selections": [
    (v26/*: any*/),
    (v24/*: any*/)
  ],
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "online",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ipAddr",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "roles",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyName",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "concreteType": "RolesConsole",
  "kind": "LinkedField",
  "name": "rolesConsole",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roleAccess",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roleAudio",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roleVideo",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "macAddress",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "webkeyId",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "webkeyBackupId",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "appVersion",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "volume",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientName",
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "playbackIds",
  "storageKey": null
},
v40 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v18/*: any*/)
],
v41 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subCategories",
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "size",
  "storageKey": null
},
v43 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "playlistId",
  "storageKey": null
},
v44 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shuffle",
  "storageKey": null
},
v45 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nrOfSongs",
  "storageKey": null
},
v46 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "WebkeyDevice",
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "DisplayName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "Serial",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "DeviceNick",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "DeviceBrand",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "DeviceModel",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "WebkeyVersion",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "Location",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "LastConnected",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "AndroidAPI",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "AndroidVersion",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "Rooted",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "Online",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "duplicate",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v47 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tracks",
  "storageKey": null
},
v48 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v49 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image",
  "storageKey": null
},
v50 = [
  (v0/*: any*/),
  (v14/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "deviceId",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "miniDrawerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v4/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/),
          (v16/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AudioDeviceConnection",
            "kind": "LinkedField",
            "name": "allAudioDevices",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AudioDeviceEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AudioDevice",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v17/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v19/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Notifications_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserSettings_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AudioDeviceRegistration_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WebkeyDashboard_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WebkeyAppManagement_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WebkeyTasks_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PlaybackTabsQuery_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AudioDevices_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Users_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Tags_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "miniDrawerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v4/*: any*/),
              (v9/*: any*/),
              (v20/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserServices",
                "kind": "LinkedField",
                "name": "services",
                "plural": false,
                "selections": [
                  (v23/*: any*/),
                  (v25/*: any*/)
                ],
                "storageKey": null
              },
              (v27/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Status",
                "kind": "LinkedField",
                "name": "status",
                "plural": false,
                "selections": [
                  (v28/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LastLogin",
                    "kind": "LinkedField",
                    "name": "lastLogin",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      (v29/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "userAgent",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v30/*: any*/),
              (v31/*: any*/),
              (v32/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/),
          (v16/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AudioDeviceConnection",
            "kind": "LinkedField",
            "name": "allAudioDevices",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AudioDeviceEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AudioDevice",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v17/*: any*/),
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "wlan",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "scheduleIds",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "uid",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "makeAndModel",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "make",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "appVersionDS",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "publicIpAddress",
                        "storageKey": null
                      },
                      (v33/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ethernetMacAddress",
                        "storageKey": null
                      },
                      (v34/*: any*/),
                      (v35/*: any*/),
                      (v36/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isOnline",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "currentPlaylistId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isPlaying",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AppStatus",
                        "kind": "LinkedField",
                        "name": "appStatus",
                        "plural": false,
                        "selections": [
                          (v15/*: any*/),
                          (v11/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "time",
                            "storageKey": null
                          },
                          (v18/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastSyncTime",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "appUpTime",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "currentM3uName",
                        "storageKey": null
                      },
                      (v18/*: any*/),
                      (v37/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "remoteVolume",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "remoteVolumeMode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "requestPCLogs",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "requestNPLogs",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastOnline",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "currentSceneId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "currentSongId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "currentAlbum",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "currentArtist",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "currentArtwork",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "remotePlaylistMode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "currentMd5",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "currentSongFilename",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalSdStorage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "usedSdStorage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "availableSdStorage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "m3uFilesOnDevice",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "songFilesOnDevice",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "latitude",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "longitude",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "skipCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "skipDisabled",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "likedSong",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "usersId",
                        "plural": true,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserProfile",
                            "kind": "LinkedField",
                            "name": "profile",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              (v2/*: any*/),
                              (v38/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserEmails",
                            "kind": "LinkedField",
                            "name": "emails",
                            "plural": true,
                            "selections": [
                              (v26/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "playlistsId",
                        "storageKey": null
                      },
                      (v39/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "enrollmentDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "internalIpAddress",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "kpi1",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "kpi3",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "kpi30",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "crossfade",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "maximumDaysOffline",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "maximumSongsOffline",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "serialNumber",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v19/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TagConnection",
            "kind": "LinkedField",
            "name": "allTags",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TagEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tag",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v40/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AudioClientConnection",
            "kind": "LinkedField",
            "name": "allClients",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AudioClientEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AudioClient",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "playlistIds",
                        "storageKey": null
                      },
                      (v39/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sceneIds",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "featuredIds",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "logo",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Preview",
                        "kind": "LinkedField",
                        "name": "preview",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "previewId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "created",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "ending",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isEnded",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isEndless",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ViewableCategory",
                        "kind": "LinkedField",
                        "name": "viewableCategories",
                        "plural": true,
                        "selections": [
                          (v1/*: any*/),
                          (v41/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "useExplicit",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ScheduleConnection",
            "kind": "LinkedField",
            "name": "allSchedule",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ScheduleEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Schedule",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "injectable",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endDate",
                        "storageKey": null
                      },
                      (v18/*: any*/),
                      (v42/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PlayingPlaylist",
                        "kind": "LinkedField",
                        "name": "playingPlaylists",
                        "plural": true,
                        "selections": [
                          (v43/*: any*/),
                          (v1/*: any*/),
                          (v37/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startTime",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endTime",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startTimeSeconds",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endTimeSeconds",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "days",
                            "storageKey": null
                          },
                          (v44/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Filenames",
                            "kind": "LinkedField",
                            "name": "filenames",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "mixId",
                                "storageKey": null
                              },
                              (v45/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AudioDeviceOld",
            "kind": "LinkedField",
            "name": "allAudioDevicesOld",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v33/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WebkeyDeviceConnection",
            "kind": "LinkedField",
            "name": "allWebkeyDevices",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WebkeyDeviceEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": (v46/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WebkeyDeviceBackupConnection",
            "kind": "LinkedField",
            "name": "allWebkeyDevicesBackup",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WebkeyDeviceBackupEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": (v46/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "VideoDeviceConnection",
            "kind": "LinkedField",
            "name": "allVideoDevices",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "VideoDeviceEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VideoDevice",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      (v33/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lanMacAddress",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      (v34/*: any*/),
                      (v35/*: any*/),
                      (v36/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WebkeyPackageConnection",
            "kind": "LinkedField",
            "name": "allWebkeyPackages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WebkeyPackageEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WebkeyPackage",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "appName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "packageName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "keyName",
                        "storageKey": null
                      },
                      (v18/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "source",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "versionCode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "versionName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "icon",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WebkeyCustomActionsConnection",
            "kind": "LinkedField",
            "name": "allWebkeyCustomActions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WebkeyCustomActionsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WebkeyCustomAction",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "actionCode",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "VideoClientConnection",
            "kind": "LinkedField",
            "name": "allVideoClients",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "VideoClientEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VideoClient",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WebkeyTaskConnection",
            "kind": "LinkedField",
            "name": "allWebkeyTasks",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WebkeyTaskEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WebkeyTask",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "instance",
                        "storageKey": null
                      },
                      (v13/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "packageId",
                        "storageKey": null
                      },
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WebkeyReport",
                        "kind": "LinkedField",
                        "name": "reports",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "deviceid",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "state",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserConnection",
            "kind": "LinkedField",
            "name": "allUsers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v27/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserProfile",
                        "kind": "LinkedField",
                        "name": "profile",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v38/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "sonosPermit",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "qsysPermit",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v30/*: any*/),
                      (v20/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "apiKey",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserServices",
                        "kind": "LinkedField",
                        "name": "services",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Password",
                            "kind": "LinkedField",
                            "name": "password",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "bcrypt",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v23/*: any*/),
                          (v25/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v32/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SonosObject",
                        "kind": "LinkedField",
                        "name": "sonos",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "songCount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastPlayedTimestamp",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "extensionMenuId",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "QsysObject",
                        "kind": "LinkedField",
                        "name": "qsys",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "pin",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "multiTenant",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "globalZoneLimit",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "serviceType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "defaultServiceType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Status",
                        "kind": "LinkedField",
                        "name": "status",
                        "plural": false,
                        "selections": [
                          (v28/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "LastLogin",
                            "kind": "LinkedField",
                            "name": "lastLogin",
                            "plural": false,
                            "selections": [
                              (v14/*: any*/),
                              (v29/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v31/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "QsysData",
                        "kind": "LinkedField",
                        "name": "qsysData",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "qsysDeviceIds",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "noOfCores",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "noOfZones",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PlaylistConnection",
            "kind": "LinkedField",
            "name": "allPlaylists",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PlaylistEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Playlist",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      (v18/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cover",
                        "storageKey": null
                      },
                      (v47/*: any*/),
                      (v42/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "failed",
                        "storageKey": null
                      },
                      (v48/*: any*/),
                      (v20/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "signedUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "genre",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "subGenre",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "tempo",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "clientsId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "duration",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AudioFile",
                        "kind": "LinkedField",
                        "name": "files",
                        "plural": true,
                        "selections": [
                          (v15/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PlaybackConnection",
            "kind": "LinkedField",
            "name": "allPlaybacks",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PlaybackEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Playback",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      (v18/*: any*/),
                      (v44/*: any*/),
                      (v49/*: any*/),
                      (v42/*: any*/),
                      (v20/*: any*/),
                      (v48/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PlaylistQueue",
                        "kind": "LinkedField",
                        "name": "playlists",
                        "plural": true,
                        "selections": [
                          (v43/*: any*/),
                          (v1/*: any*/),
                          (v45/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "filename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CategoryConnection",
            "kind": "LinkedField",
            "name": "allCategories",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CategoryEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Category",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      (v41/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sortOrder",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SceneConnection",
            "kind": "LinkedField",
            "name": "allScenes",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SceneEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Scene",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      (v18/*: any*/),
                      (v49/*: any*/),
                      (v42/*: any*/),
                      (v20/*: any*/),
                      (v48/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PlaylistQueueScenes",
                        "kind": "LinkedField",
                        "name": "playlists",
                        "plural": true,
                        "selections": [
                          (v43/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v47/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SceneCategory",
                        "kind": "LinkedField",
                        "name": "newCategory",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "category",
                            "storageKey": null
                          },
                          (v41/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "NPLogConnection",
            "kind": "LinkedField",
            "name": "allNPLogs",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NPLogEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NPLog",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v50/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PCLogConnection",
            "kind": "LinkedField",
            "name": "allPCLogs",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PCLogEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PCLog",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v50/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SonosMenu",
            "kind": "LinkedField",
            "name": "allSonosMenu",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v18/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SonosMenu",
                "kind": "LinkedField",
                "name": "childrenMenus",
                "plural": true,
                "selections": (v40/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "2b6fdd7ffb6b5ac2a0fd4b0e9688bd82",
    "metadata": {},
    "name": "miniDrawerQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5ae8b7a201d5750d369bd7122d9a9625';

module.exports = node;

/**
 * External dependencies
 */

import graphql from "babel-plugin-relay/macro";

export const ALL_QSYS_DEVICES = graphql`
  query allQsysDevicesQuery($deviceIds: [String]) {
    viewer {
      allQsysDevices(deviceIds: $deviceIds) {
        id
        name
        description
        dateCreated
        currentLoginToken
        deviceId
        systemVersion
        systemIsEmulating
        generalSchedule
        isGeneralScheduleActive
        coreUptime
        coreDate
        coreTime
        ipAddress
        totalStorage
        availableStorage
        currentUser {
          id
          profile {
            name
            clientId
          }
          emails {
            address
          }
        }
        countryCode
        userIds {
          id
          profile {
            name
          }
          emails {
            address
          }
        }
        noOfZones
        lastHeartbeat
        client {
          id
          name
        }
        zones {
          zoneId
          zoneName
          songCount
          songId
          currentAlbum
          currentTitle
          currentArtist
          currentArtwork
          reason
          positionMillis
          durationMillis
          isScheduleActive
          schedule
          mixType
          mixId
          mixName
          volume
        }
      }
    }
  }
`;

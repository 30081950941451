/**
 * @fileoverview This file defines the `DeviceControlZoneItem` component which is a card that displays information about a device control zone.
 */

// Importing necessary libraries and components
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DeviceControlZoneItemEdit from "./deviceControlZoneItemEdit";
import DeviceControlZoneItemDelete from "./deviceControlZoneItemDelete";

/**
 * @typedef {Object} Theme
 * @property {function} theme.spacing - A helper function to use the spacing scale.
 */

/**
 * Styles for the `DeviceControlZoneItem` component.
 * @param {Theme} theme - The theme provided by Material-UI.
 * @returns {Object} An object containing class names and their respective styles.
 */
const styles = () => ({
  card: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "6px",
    background: "#f9f9f9",
  },
  typo1: {
    display: "flex",
    alignItems: "center",
    color: "#222831",
    fontSize: "16px",
  },
  typo2: {
    paddingLeft: 4,
    color: "#222831",
    fontWeight: "lighter",
    letterSpacing: "0.06em",
    marginBottom: "3px",
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    flex: 1,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
});

/**
 * @typedef {Object} Zone
 * @property {string} zoneId - The ID of the zone.
 * @property {string} mixType - The type of mix.
 * @property {string} currentAlbum - The current album.
 * @property {string} currentArtist - The current artist.
 * @property {string} zoneName - The name of the zone.
 * @property {string} mixId - The ID of the mix.
 * @property {string} currentTitle - The current title.
 * @property {string} reason - The reason for the current status.
 * @property {number} volume - The current volume.
 * @property {string} songId - The current song ID.
 * @property {number} durationMillis - Duration in milliseconds.
 * @property {boolean} isScheduleActive - Whether schedule is active.
 * @property {string} schedule - Schedule information.
 */

/**
 * @typedef {Object} Props
 * @property {Object} classes - The classes passed down from `withStyles`.
 * @property {Zone} zone - The zone data to be displayed.
 */

/**
 * The `DeviceControlZoneItem` component displays a card with information about a device control zone.
 * @param {Props} props - The properties that define the `DeviceControlZoneItem` component.
 * @returns {JSX.Element} The `DeviceControlZoneItem` component.
 */
function DeviceControlZoneItem(props) {
  const { classes, zone, deviceId } = props;
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);

  const handleEdit = () => {
    setOpenEdit(true);
  };

  const handleDelete = () => {
    setOpenDelete(true);
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <div className={classes.buttonContainer}>
          <IconButton onClick={() => handleEdit(zone)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(zone)}>
            <DeleteIcon />
          </IconButton>
        </div>
        <div className={classes.horizontalContainer}>
          <div className={classes.verticalContainer}>
            <Typography
              className={classes.typo2}
              variant="body2"
              color="textSecondary"
            >
              <b>Zone ID: </b>
              {zone?.zoneId}
            </Typography>
            <Typography
              className={classes.typo2}
              variant="body2"
              color="textSecondary"
            >
              <b>Mix Type: </b>
              {zone?.mixType}
            </Typography>
            <Typography
              className={classes.typo2}
              variant="body2"
              color="textSecondary"
            >
              <b>Current Album: </b>
              {zone?.currentAlbum}
            </Typography>
            <Typography
              className={classes.typo2}
              variant="body2"
              color="textSecondary"
            >
              <b>Current Artist: </b>
              {zone?.currentArtist}
            </Typography>
            <Typography
              className={classes.typo2}
              variant="body2"
              color="textSecondary"
            >
              <b>Mix Type: </b>
              {zone?.mixType}
            </Typography>
            <Typography
              className={classes.typo2}
              variant="body2"
              color="textSecondary"
            >
              <b>Mix Name: </b>
              {zone?.mixName}
            </Typography>
            <Typography
              className={classes.typo2}
              variant="body2"
              color="textSecondary"
            >
              <b>Song ID: </b>
              {zone?.songId || "No song playing"}
            </Typography>
            <Typography
              className={classes.typo2}
              variant="body2"
              color="textSecondary"
            >
              <b>Duration: </b>
              {zone?.durationMillis
                ? `${Math.floor(zone.durationMillis / 1000)}s`
                : "N/A"}
            </Typography>
          </div>
          <div className={classes.verticalContainer}>
            <Typography
              className={classes.typo2}
              variant="body2"
              color="textSecondary"
            >
              <b>Zone name: </b>
              {zone?.zoneName}
            </Typography>
            <Typography
              className={classes.typo2}
              variant="body2"
              color="textSecondary"
            >
              <b>Mix id: </b>
              {zone?.mixId}
            </Typography>
            <Typography
              className={classes.typo2}
              variant="body2"
              color="textSecondary"
            >
              <b>Current Title: </b>
              {zone?.currentTitle}
            </Typography>
            <Typography
              className={classes.typo2}
              variant="body2"
              color="textSecondary"
            >
              <b>Current Status: </b>
              {zone?.reason || "paused"}
            </Typography>
            <Typography
              className={classes.typo2}
              variant="body2"
              color="textSecondary"
            >
              <b>Current volume: </b>
              {(zone?.volume || 0).toFixed(2)} db
            </Typography>
          </div>
        </div>
        <DeviceControlZoneItemEdit
          deviceId={deviceId}
          open={openEdit || false}
          zone={zone}
          handleClose={(value) => setOpenEdit(value)}
        />
        <DeviceControlZoneItemDelete
          deviceId={deviceId}
          open={openDelete || false}
          zone={zone}
          handleClose={() => setOpenDelete(false)}
        />
      </CardContent>
    </Card>
  );
}

export default withStyles(styles)(DeviceControlZoneItem);

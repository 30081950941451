import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useLazyLoadQuery } from "react-relay";
import { ALL_QSYS_DEVICES } from "../../qsys_devices/queries/allQsysDevices";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const styles = (theme) => ({
  dialog: {
    padding: theme.spacing(2),
    minWidth: 400,
  },
  dialogTitle: {
    marginBottom: theme.spacing(2),
  },
  searchField: {
    margin: theme.spacing(1),
    width: "96%",
  },
  close: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  closeIcon: {
    width: 24,
    height: 24,
  },
  table: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  selectedRow: {
    backgroundColor: "#e0e0e0",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#d5d5d5",
    },
  },
  row: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
    padding: theme.spacing(2),
  },
});

function AddQsysDeviceModal({
  classes,
  open,
  onClose,
  onSelect,
  qsysDeviceIds,
}) {
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [queryKey, setQueryKey] = useState(0);

  // Reset state and force refetch when modal opens
  React.useEffect(() => {
    if (open) {
      setSelectedDevice(null);
      setSearchQuery("");
      setQueryKey((prev) => prev + 1);
    }
  }, [open]);

  const { viewer } = useLazyLoadQuery(
    ALL_QSYS_DEVICES,
    {},
    {
      fetchPolicy: "store-and-network",
      fetchKey: queryKey,
      networkCacheConfig: { force: true },
    }
  );

  const availableDevices =
    viewer?.allQsysDevices?.filter(
      (device) => !device.client && !qsysDeviceIds?.includes(device.id)
    ) || [];

  const filteredDevices = availableDevices.filter((device) => {
    const searchLower = searchQuery.toLowerCase();
    return (
      device.deviceId?.toLowerCase().includes(searchLower) ||
      device.name?.toLowerCase().includes(searchLower) ||
      device.ipAddress?.toLowerCase().includes(searchLower) ||
      device.systemVersion?.toLowerCase().includes(searchLower)
    );
  });

  const handleSelect = () => {
    if (selectedDevice) {
      onSelect(selectedDevice);
      onClose();
    }
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <div className={classes.close}>
        <IconButton onClick={onClose}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </div>

      <DialogTitle className={classes.dialogTitle}>
        Select Q-SYS Core to Add
      </DialogTitle>

      <div className={classes.dialog}>
        <TextField
          className={classes.searchField}
          variant="outlined"
          placeholder="Search devices..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Device ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>IP Address</TableCell>
              <TableCell>System Version</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredDevices.map((device) => (
              <TableRow
                key={device.id}
                className={
                  selectedDevice?.id === device.id
                    ? classes.selectedRow
                    : classes.row
                }
                onClick={() => setSelectedDevice(device)}
              >
                <TableCell>{device.deviceId}</TableCell>
                <TableCell>{device.name || "No Name"}</TableCell>
                <TableCell>{device.ipAddress}</TableCell>
                <TableCell>{device.systemVersion}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            onClick={onClose}
            style={{ backgroundColor: "#808080", color: "white" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSelect}
            disabled={!selectedDevice}
            style={{
              backgroundColor: "#3BABA1",
              color: "white",
            }}
          >
            Add Device
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default withStyles(styles)(AddQsysDeviceModal);

import React, { Component } from "react";
import { withRouter, Link } from "found";
import jwt from "jsonwebtoken";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Collapse from "@material-ui/core/Collapse";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Badge from "@material-ui/core/Badge";
import PersonIcon from "@material-ui/icons/Person";
import LibraryMusicIcon from "@material-ui/icons/LibraryMusic";
import DevicesIcon from "@material-ui/icons/Devices";
import RadioIcon from "@material-ui/icons/Radio";
import TabletMacIcon from "@material-ui/icons/TabletMac";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import GraphicEqIcon from "@material-ui/icons/GraphicEq";
import CompareArrowsIcon from "@material-ui/icons/CompareArrowsRounded";
import PhonelinkRingIcon from "@material-ui/icons/PhonelinkRingRounded";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheckRounded";
import Divider from "@material-ui/core/Divider";

import permissions from "./authentication/isPermited";
import permissions2 from "./authentication/UIPermissions";

const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    background: theme.palette.background.paper,
  },
  listItem: {
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: "12px",
    selected: {
      background: "red",
    },
  },
  badge: {
    marginTop: theme.spacing(1),
  },
});

class TileData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: props,
      userId: localStorage.getItem("user_id"),
      user: jwt.decode(localStorage.getItem("authentication_token")),
      expand: true,
    };
    this.handleExpand = this.handleExpand.bind(this);
  }

  handleExpand() {
    this.setState({
      expand: !this.state.expand,
    });
  }

  getSelectedStyle = (path) => {
    // If tab is active
    if (window.location.pathname === path) {
      return {
        width: "220px",
        backgroundColor: "#c5b4ea",
        color: "#030416",
        borderRadius: "12px",
      };
    } else {
      return {
        width: "220px",
        backgroundColor: "#fff",
        color: "#5f607d",
        borderRadius: "12px",
      };
    }
  };

  getSelectedIconStyle = (path) => {
    if (window.location.pathname === path) {
      return {
        color: "#030416",
      };
    } else {
      return {
        color: "#5f607d",
      };
    }
  };
  render() {
    const { classes, open, router, unregisteredADNo, methods } = this.props;
    const { expand } = this.state;
    const theMethods = methods ? methods : [];

    return (
      <div
        className={classes.root}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflowX: "hidden",
        }}
      >
        <List
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {permissions2("5cac91adf078fb33b8832b35", theMethods) ||
            permissions2("5d6fb8e1fa3fc5cc1d166018", theMethods) ||
            permissions2("5d6fb9effa3fc5cc1d166779", theMethods) ||
            (permissions2("5db190943c6f6a663114a516", theMethods) &&
              open(
                <ListSubheader
                  style={{
                    color: "#000",
                    fontSize: "medium",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  <Divider />
                  System
                  <Divider />
                </ListSubheader>
              ))}
          {/*permissions2("5cac91adf078fb33b8832b35", theMethods) && (
            <ListItem
              className={classes.listItem}
              button
              onClick={() => router.push("/home/dashboard")}
              id="dashboard"
            >
              <ListItemIcon>
                <DonutSmallIcon />
              </ListItemIcon>
              <ListItemText
                primary="Dashboard"
                primaryTypographyProps={{ style: { fontWeight: "bold" } }}
              />
            </ListItem>
          )*/}
          {/*<ListItem
              className={classes.listItem}
              button
              onClick={() => router.push("/home/upload_music")}
              id="upload_music"
            >
              <ListItemIcon>
                <CloudUploadIcon />
              </ListItemIcon>
              <ListItemText
                primary="Upload Music"
                primaryTypographyProps={{ style: { fontWeight: "bold" } }}
              />
            </ListItem>
          )*/}
          {permissions2("5d6fb8e1fa3fc5cc1d166018", theMethods) && (
            <Link
              to="/home/control_dashboard"
              style={{ textDecoration: "none" }}
            >
              <ListItem
                className={classes.listItem}
                button
                id="control_dashboard"
                style={this.getSelectedStyle("/home/control_dashboard")}
                selected={
                  window.location.pathname === "/home/control_dashboard"
                }
              >
                <ListItemIcon>
                  <CompareArrowsIcon
                    style={this.getSelectedIconStyle("/home/control_dashboard")}
                  />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ style: { fontWeight: "bold" } }}
                  primary="Control"
                />
              </ListItem>
            </Link>
          )}
          {permissions2("5d6fb8e1fa3fc5cc1d166018", theMethods) && (
            <Link
              to="/home/recovery_dashboard"
              style={{ textDecoration: "none" }}
            >
              <ListItem
                className={classes.listItem}
                button
                id="recovery_dashboard"
                style={this.getSelectedStyle("/home/recovery_dashboard")}
                selected={
                  window.location.pathname === "/home/recovery_dashboard"
                }
              >
                <ListItemIcon>
                  <CompareArrowsIcon
                    style={this.getSelectedIconStyle(
                      "/home/recovery_dashboard"
                    )}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Recovery"
                  primaryTypographyProps={{ style: { fontWeight: "bold" } }}
                />
              </ListItem>
            </Link>
          )}
          {permissions2("5d6fb9effa3fc5cc1d166779", theMethods) && (
            <Link to="/home/control_apps" style={{ textDecoration: "none" }}>
              <ListItem
                className={classes.listItem}
                button
                id="control_apps"
                style={this.getSelectedStyle("/home/control_apps")}
                selected={window.location.pathname === "/home/control_apps"}
              >
                <ListItemIcon>
                  <PhonelinkRingIcon
                    style={this.getSelectedIconStyle("/home/control_apps")}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Control Apps"
                  primaryTypographyProps={{ style: { fontWeight: "bold" } }}
                />
              </ListItem>
            </Link>
          )}
          {permissions2("5db190943c6f6a663114a516", theMethods) && (
            <Link to="/home/control_tasks" style={{ textDecoration: "none" }}>
              <ListItem
                className={classes.listItem}
                button
                id="control_tasks"
                style={this.getSelectedStyle("/home/control_tasks")}
                selected={window.location.pathname === "/home/control_tasks"}
              >
                <ListItemIcon>
                  <PlaylistAddCheckIcon
                    style={this.getSelectedIconStyle("/home/control_tasks")}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Control Tasks"
                  primaryTypographyProps={{ style: { fontWeight: "bold" } }}
                />
              </ListItem>
            </Link>
          )}
          <Divider />

          {(permissions2("5cac99893630643c2a423813", theMethods) ||
            permissions2("5cac9d063630643c2a423815", theMethods) ||
            permissions2("5cac9df43630643c2a423817", theMethods) ||
            permissions2("5cac9e763630643c2a423819", theMethods) ||
            permissions2("5cac9fa53630643c2a42381b", theMethods)) &&
            open && (
              <ListSubheader
                style={{
                  color: "#000",
                  fontSize: "medium",
                  fontWeight: "bold",
                  width: "100%",
                }}
              >
                <Divider />
                Audio
                <Divider />
              </ListSubheader>
            )}

          {permissions2("5cac99893630643c2a423813", theMethods) &&
            unregisteredADNo > 0 && (
              <Link
                to="/home/audio_devices_registration"
                style={{ textDecoration: "none" }}
              >
                <ListItem
                  className={classes.listItem}
                  button
                  id="audio_devices_registration"
                >
                  <ListItemIcon>
                    <Badge
                      badgeContent={unregisteredADNo}
                      color="error"
                      className={classes.badge}
                    >
                      <DevicesIcon />
                    </Badge>
                  </ListItemIcon>
                  <ListItemText
                    primary="Registration"
                    primaryTypographyProps={{ style: { fontWeight: "bold" } }}
                  />
                </ListItem>
              </Link>
            )}

          {permissions2("5cac9d063630643c2a423815", theMethods) && (
            <Link to="/home/audio_devices" style={{ textDecoration: "none" }}>
              <ListItem
                className={classes.listItem}
                button
                id="audio_devices"
                style={this.getSelectedStyle("/home/audio_devices")}
                selected={window.location.pathname === "/home/audio_devices"}
              >
                <ListItemIcon>
                  <RadioIcon
                    style={this.getSelectedIconStyle("/home/audio_devices")}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Active Devices"
                  primaryTypographyProps={{ style: { fontWeight: "bold" } }}
                />
              </ListItem>
            </Link>
          )}
          {permissions([1], [1]) && (
            <Link to="/home/qsys_devices" style={{ textDecoration: "none" }}>
              <ListItem
                className={classes.listItem}
                button
                id="qsys_devices"
                style={this.getSelectedStyle("/home/qsys_devices")}
                selected={window.location.pathname === "/home/qsys_devices"}
              >
                <ListItemIcon>
                  <TabletMacIcon
                    style={this.getSelectedIconStyle("/home/qsys_devices")}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Qsys Devices"
                  primaryTypographyProps={{ style: { fontWeight: "bold" } }}
                />
              </ListItem>
            </Link>
          )}
          {/*permissions2("5cac9df43630643c2a423817", theMethods) && (
            <ListItem
              className={classes.listItem}
              button
              onClick={() => router.push("/home/blacklist_audio_devices")}
              id="blacklist_audio_devices"
              selected={
                window.location.pathname === "/home/blacklist_audio_devices"
              }
            >
              <ListItemIcon>
                <MusicOffIcon />
              </ListItemIcon>
              <ListItemText
                primary="Blacklisted Devices"
                primaryTypographyProps={{ style: { fontWeight: "bold" } }}
              />
            </ListItem>
          )*/}

          {permissions2("5cac9e763630643c2a423819", theMethods) && (
            <Link to="/home/playbacks" style={{ textDecoration: "none" }}>
              <ListItem
                className={classes.listItem}
                button
                style={this.getSelectedStyle("/home/playbacks")}
                id="playbacks"
                selected={window.location.pathname === "/home/playbacks"}
              >
                <ListItemIcon>
                  <LibraryMusicIcon
                    style={this.getSelectedIconStyle("/home/playbacks")}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Playbacks"
                  primaryTypographyProps={{ style: { fontWeight: "bold" } }}
                />
              </ListItem>
            </Link>
          )}
          {permissions2("61138c2c74598f0ed69db4c7", theMethods) && (
            <Link to="/home/sonos" style={{ textDecoration: "none" }}>
              <ListItem
                className={classes.listItem}
                button
                style={this.getSelectedStyle("/home/sonos")}
                id="sonos"
                selected={window.location.pathname === "/home/sonos"}
              >
                <ListItemIcon>
                  <GraphicEqIcon
                    style={this.getSelectedIconStyle("/home/permissions")}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Sonos Menu"
                  primaryTypographyProps={{ style: { fontWeight: "bold" } }}
                />
              </ListItem>
            </Link>
          )}
          {permissions2("61138c2c74598f0ed69db4c7", theMethods) && (
            <Link to="/home/qsys" style={{ textDecoration: "none" }}>
              <ListItem
                className={classes.listItem}
                button
                style={this.getSelectedStyle("/home/qsys")}
                id="qsys"
                selected={window.location.pathname === "/home/qsys"}
              >
                <ListItemIcon>
                  <GraphicEqIcon
                    style={this.getSelectedIconStyle("/home/permissions")}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="QSYS Menu"
                  primaryTypographyProps={{ style: { fontWeight: "bold" } }}
                />
              </ListItem>
            </Link>
          )}
          {permissions2("5cade6648d3306167367505b", theMethods) && (
            <Link to="/home/users" style={{ textDecoration: "none" }}>
              <ListItem
                className={classes.listItem}
                button
                id="users"
                style={this.getSelectedStyle("/home/users")}
                selected={window.location.pathname === "/home/users"}
              >
                <ListItemIcon>
                  <PersonIcon
                    style={this.getSelectedIconStyle("/home/users")}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Users"
                  primaryTypographyProps={{ style: { fontWeight: "bold" } }}
                />
              </ListItem>
            </Link>
          )}
          {permissions2("5cade76e8d3306167367505d", theMethods) && (
            <Link to="/home/permissions" style={{ textDecoration: "none" }}>
              <ListItem
                className={classes.listItem}
                button
                style={this.getSelectedStyle("/home/permissions")}
                id="permissions"
                selected={window.location.pathname === "/home/permissions"}
              >
                <ListItemIcon>
                  <AccessibilityIcon
                    style={this.getSelectedIconStyle("/home/permissions")}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Permissions"
                  primaryTypographyProps={{ style: { fontWeight: "bold" } }}
                />
              </ListItem>
            </Link>
          )}
          {permissions2("5cac9fa53630643c2a42381b", theMethods) && (
            <ListItem
              button
              onClick={this.handleExpand}
              className={classes.listItem}
            >
              <ListItemText
                primaryTypographyProps={{ style: { fontWeight: "bold" } }}
                primary="Audio Reports"
              />
              {expand ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          )}

          {permissions2("5cac9fa53630643c2a42381b", theMethods) && (
            <Collapse in={expand} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link
                  to="/home/audio_reports"
                  style={{ textDecoration: "none" }}
                >
                  <ListItem
                    className={classes.listItem}
                    button
                    id="audio_reports"
                    style={this.getSelectedStyle("/home/audio_reports")}
                    selected={
                      window.location.pathname === "/home/audio_reports"
                    }
                  >
                    <ListItemIcon>
                      <EqualizerIcon
                        style={this.getSelectedIconStyle("/home/audio_reports")}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Device Reports"
                      primaryTypographyProps={{ style: { fontWeight: "bold" } }}
                    />
                  </ListItem>
                </Link>
                <Link
                  to="/home/song_reporting"
                  style={{ textDecoration: "none" }}
                >
                  <ListItem
                    className={classes.listItem}
                    button
                    style={this.getSelectedStyle("/home/song_reporting")}
                    selected={
                      window.location.pathname === "/home/song_reporting"
                    }
                  >
                    <ListItemIcon>
                      <ThumbsUpDownIcon
                        style={this.getSelectedIconStyle("/home/audio_reports")}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Song Reporting"
                      primaryTypographyProps={{ style: { fontWeight: "bold" } }}
                    />
                  </ListItem>
                </Link>
                <Link
                  to="/home/search_reports"
                  style={{ textDecoration: "none" }}
                >
                  <ListItem
                    className={classes.listItem}
                    button
                    id="search_reports"
                    style={this.getSelectedStyle("/home/search_reports")}
                    selected={
                      window.location.pathname === "/home/search_reports"
                    }
                  >
                    <ListItemIcon>
                      <EqualizerIcon
                        style={this.getSelectedIconStyle(
                          "/home/search_reports"
                        )}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Search Reports"
                      primaryTypographyProps={{ style: { fontWeight: "bold" } }}
                    />
                  </ListItem>
                </Link>
              </List>
            </Collapse>
          )}
          <Divider />

          {/*
            (permissions2("5caca31b3630643c2a42381d", theMethods) ||
              permissions2("5caca3a03630643c2a42381f", theMethods) ||
              permissions2("5caca42a3630643c2a423821", theMethods) ||
              permissions2("5caca4c53630643c2a423823", theMethods) ||
              permissions2("5cade4b98d33061673675059", theMethods)) &&
            open && (
              <ListSubheader
                style={{
                  color: "#000",
                  fontSize: "medium",
                  fontWeight: "bold",
                  width: "100%",
                }}
              >
                <Divider />
                Video
                <Divider />
              </ListSubheader>
            )*/}

          {/*permissions2("5caca31b3630643c2a42381d", theMethods) && (
            <ListItem
              className={classes.listItem}
              button
              style={this.getSelectedStyle("/home/video_devices")}
              onClick={() => router.push("/home/video_devices")}
              id="video_devices"
              selected={window.location.pathname === "/home/video_devices"}
            >
              <ListItemIcon>
                <MovieIcon
                  style={this.getSelectedIconStyle("/home/video_devices")}
                />
              </ListItemIcon>
              <ListItemText
                primary="Devices"
                primaryTypographyProps={{ style: { fontWeight: "bold" } }}
              />
            </ListItem>
          )*/}
          {/*permissions2("5caca3a03630643c2a42381f", theMethods) && (
            <ListItem
              className={classes.listItem}
              button
              onClick={() => router.push("/home/blacklist_video_devices")}
              id="blacklist_video_devices"
              selected={
                window.location.pathname === "/home/blacklist_video_devices"
              }
            >
              <ListItemIcon>
                <TvOffIcon />
              </ListItemIcon>
              <ListItemText
                primary="Blacklisted Devices"
                primaryTypographyProps={{ style: { fontWeight: "bold" } }}
              />
            </ListItem>
          )*/}
          {/*permissions2("5caca42a3630643c2a423821", theMethods) && (
            <ListItem
              className={classes.listItem}
              button
              onClick={() => router.push("/home/video_profiles")}
              id="video_profiles"
              style={this.getSelectedStyle("/home/video_profiles")}
              selected={window.location.pathname === "/home/video_profiles"}
            >
              <ListItemIcon>
                <VideoLabelIcon
                  style={this.getSelectedIconStyle("/home/video_profiles")}
                />
              </ListItemIcon>
              <ListItemText
                primary="Profiles"
                primaryTypographyProps={{ style: { fontWeight: "bold" } }}
              />
            </ListItem>
          )*/}
          {/*permissions2("5caca4c53630643c2a423823", theMethods) && (
            <ListItem
              className={classes.listItem}
              button
              onClick={() => router.push("/home/video_campaigns")}
              id="video_campaigns"
              style={this.getSelectedStyle("/home/video_campaigns")}
              selected={window.location.pathname === "/home/video_campaigns"}
            >
              <ListItemIcon>
                <DeviceHubIcon
                  style={this.getSelectedIconStyle("/home/video_campaigns")}
                />
              </ListItemIcon>
              <ListItemText
                primary="Campaigns"
                primaryTypographyProps={{ style: { fontWeight: "bold" } }}
              />
            </ListItem>
          )*/}
          {/*permissions2("5cade4b98d33061673675059", theMethods) && (
            <ListItem
              className={classes.listItem}
              button
              disabled
              id="video_reports"
            >
              <ListItemIcon>
                <EqualizerIcon />
              </ListItemIcon>
              <ListItemText
                primary="Reports"
                primaryTypographyProps={{ style: { fontWeight: "bold" } }}
              />
            </ListItem>
          )*/}
          {permissions([1]) && <Divider />}

          {permissions([1]) && open && (
            <ListSubheader
              style={{
                color: "#000",
                fontSize: "medium",
                fontWeight: "bold",
                width: "100%",
              }}
            >
              <Divider />
              Access control
              <Divider />
            </ListSubheader>
          )}
          {/*permissions2("5cade8328d3306167367505f", theMethods) && (
            <ListItem
              className={classes.listItem}
              button
              disabled
              id="pending_users"
            >
              <ListItemIcon>
                <PersonOutlineIcon />
              </ListItemIcon>
              <ListItemText
                primary="Pending"
                primaryTypographyProps={{ style: { fontWeight: "bold" } }}
              />
            </ListItem>
          )*/}
          {/* permissions2("5cade8b38d33061673675061", theMethods) && (
            <ListItem
              className={classes.listItem}
              button
              disabled
              id="user_reports"
            >
              <ListItemIcon>
                <EqualizerIcon />
              </ListItemIcon>
              <ListItemText
                primary="Reports"
                primaryTypographyProps={{ style: { fontWeight: "bold" } }}
              />
            </ListItem>
          )*/}
          {/* permissions2("5cade9708d33061673675063", theMethods) && (
            <ListItem
              className={classes.listItem}
              button
              id="events"
              onClick={() => router.push("/home/events")}
              selected={window.location.pathname === "/home/events"}
            >
              <ListItemIcon>
                <EventNoteRoundedIcon />
              </ListItemIcon>
              <ListItemText
                primary="Events"
                primaryTypographyProps={{ style: { fontWeight: "bold" } }}
              />
            </ListItem>
          )*/}
          {/* permissions2("5db1724b3c6f6a6631d1578f", theMethods) && (
            <ListItem
              className={classes.listItem}
              button
              id="events"
              style={this.getSelectedStyle("/home/devicelogs")}
              onClick={() => router.push("/home/devicelogs")}
              selected={window.location.pathname === "/home/devicelogs"}
            >
              <ListItemIcon>
                <TocIcon
                  style={this.getSelectedIconStyle("/home/devicelogs")}
                />
              </ListItemIcon>
              <ListItemText
                primary="Device Logs"
                primaryTypographyProps={{ style: { fontWeight: "bold" } }}
              />
            </ListItem>
          )*/}
          {/* permissions2("5caded6e8d33061673675065", theMethods) && (
            <Divider />
          )*/}
          {/* permissions2("5caded6e8d33061673675065", theMethods) && (
            <ListItem
              className={classes.listItem}
              button
              onClick={() => router.push("/home/tags")}
              id="tags"
              style={this.getSelectedStyle("/home/tags")}
              selected={window.location.pathname === "/home/tags"}
            >
              <ListItemIcon>
                <LocalOfferIcon
                  style={this.getSelectedIconStyle("/home/tags")}
                />
              </ListItemIcon>
              <ListItemText
                primary="Tags"
                primaryTypographyProps={{ style: { fontWeight: "bold" } }}
              />
            </ListItem>
          )*/}
          {/* (
            <ListItem
              className={classes.listItem}
              disabled
              button
              onClick={() => router.push("/home/player")}
              id="player"
            >
              <ListItemIcon>
                <PlayCircleOutlineIcon />
              </ListItemIcon>
              <ListItemText
                primary="Player"
                primaryTypographyProps={{ style: { fontWeight: "bold" } }}
              />
            </ListItem>
          )*/}
          <Divider />
          <Link to="/home/login" style={{ textDecoration: "none" }}>
            <ListItem className={classes.listItem} button>
              <ListItemIcon>
                <PowerSettingsNewIcon />
              </ListItemIcon>
              <ListItemText
                primary="Logout"
                primaryTypographyProps={{ style: { fontWeight: "bold" } }}
              />
            </ListItem>
          </Link>
        </List>
      </div>
    );
  }
}

TileData.propTypes = {
  userRoot: PropTypes.object,
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(TileData));

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { createFragmentContainer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import ReactTable from "react-table";
import "react-table/react-table.css";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import PersonIcon from "@material-ui/icons/Person";
import UsersControl from "./UsersControl";
import roles from "./userRoles";
import permissions2 from "../authentication/UIPermissions";
import SM from "../subscriptions/index";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "0px 0px 0px 0px",
  },
  typo: {
    padding: 0,
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "nowrap",
  },
};

const openRow = "#DCDCDC";

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      userControlId: "none",
      userControl: {},
      selectedRow: "",
    };
    this.handleAddColumsMenu = this.handleAddColumsMenu.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleControlToggle = this.handleControlToggle.bind(this);
    this.toggleClose = this.toggleClose.bind(this);
    this.selectRow = this.selectRow.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidMount() {
    const loginProcedure = async () => {
      if (SM.token) {
        this.subscription1 = await SM.UserSubscription(SM.token);
        this.subscription2 = await SM.AudioDeviceSubscription(SM.token, null, [
          "isOnline",
          "clientId",
          "isPlaying",
          "name",
          "tags.*",
          "usersId",
          "deviceStatus",
        ]);
      }
    };
    loginProcedure();
  }

  async componentWillUnmount() {
    try {
      await this.subscription1?.dispose();
      await this.subscription2?.dispose();
    } catch (e) {
      console.log("componentWillUnmount miniDrawer Error:", e);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.userControlId !== "none") {
      const userControl = nextProps?.viewer?.allUsers?.edges
        ?.filter((item) => item?.node)
        .filter((data) =>
          data.node?.id === this.state?.userControlId ? data?.node : null
        );
      this.setState({ userControl: userControl[0]?.node });
    }
  }

  handleControlToggle(user) {
    this.setState({
      userControlId: user?.id,
      userControl: user,
    });
  }

  toggleClose() {
    this.setState({
      userControlId: "none",
    });
  }

  myCallBack(data) {
    this.setState({
      clientId: data,
    });
    return data;
  }

  selectRow(userId) {
    this.setState({ selectedRow: userId });
  }

  handleAddColumsMenu(e) {
    this.setState({ anchorEl: e.currentTarget });
  }

  handleClose() {
    this.setState({
      anchorEl: null,
    });
  }

  render() {
    const { classes, viewer, methods } = this.props;
    const { userControlId, userControl, selectedRow } = this.state;
    const fullData = viewer?.allUsers?.edges
      ?.filter((item) => item && item.node && item.node.profile)
      .map((item) => item.node);
    const pageSize = 25;
    const tableColumns = [
      {
        id: "status",
        Header: "",
        accessor: (a) => (a?.status?.online ? "Online" : "Offline"),
        width: 25,
        Cell: (row) => (
          <div
            style={{
              display: "flex",
              color: row.original?.status?.online ? "green" : "red",
            }}
          >
            <PersonIcon style={{ width: 16, height: 16, margin: "auto" }} />
          </div>
        ),
        filterable: false,
      },
      {
        id: "verified",
        Header: "Verify",
        accessor: (a) => (a.emails[0].verified ? "Verified" : "Un-verified"),
        width: 50,
        Cell: (row) => (
          <div
            style={{
              display: "flex",
              color: row.original.emails[0].verified ? "green" : "red",
            }}
          >
            {row.original.emails[0].verified ? (
              <DoneIcon style={{ width: 16, height: 16, margin: "auto" }} />
            ) : (
              <CloseIcon style={{ width: 16, height: 16, margin: "auto" }} />
            )}
          </div>
        ),
        filterable: false,
      },
      {
        id: "name",
        Header: "Name",
        accessor: (a) => a.profile.name,
        width: 130,
      },
      {
        id: "email",
        Header: "Email",
        accessor: (a) => a.emails[0].address,
        width: 170,
      },
      {
        id: "clientId",
        Header: "Audio Client",
        accessor: (a) => {
          const userClient = this.props.viewer?.allClients?.edges?.filter(
            (item) => item && item.node && item.node.id === a.profile.clientId
          );
          return userClient[0] ? userClient[0].node.name : "None";
        },
        width: 120,
      },
      {
        id: "companyName",
        Header: "Company Name",
        accessor: (a) => a.companyName,
        width: 120,
      },
      {
        id: "sonosPermit",
        Header: "Sonos",
        accessor: (a) => (a.profile.sonosPermit ? "Yes" : "No"),
        width: 50,
        Cell: (row) => (
          <div
            style={{
              display: "flex",
              color: row.original.profile.sonosPermit ? "green" : "red",
            }}
          >
            {row.original.profile.sonosPermit ? (
              <DoneIcon style={{ width: 16, height: 16, margin: "auto" }} />
            ) : (
              <CloseIcon style={{ width: 16, height: 16, margin: "auto" }} />
            )}
          </div>
        ),
      },
      {
        id: "qsysPermit",
        Header: "Q-SYS",
        accessor: (a) => (a.profile.qsysPermit ? "Yes" : "No"),
        width: 50,
        Cell: (row) => (
          <div
            style={{
              display: "flex",
              color: row.original.profile.qsysPermit ? "green" : "red",
            }}
          >
            {row.original.profile.qsysPermit ? (
              <DoneIcon style={{ width: 16, height: 16, margin: "auto" }} />
            ) : (
              <CloseIcon style={{ width: 16, height: 16, margin: "auto" }} />
            )}
          </div>
        ),
      },
      {
        id: "songCount",
        Header: "Song count",
        accessor: (a) => a.sonos?.songCount || "",
        width: 74,
      },
      {
        id: "lastPlayedTimestamp",
        Header: "Last Played",
        accessor: (a) =>
          a.sonos?.lastPlayedTimestamp
            ? moment(a.sonos?.lastPlayedTimestamp).format("YYYY-MM-DD, HH:mm")
            : "",
        width: 120,
      },
      {
        Header: "Roles",
        columns: [
          {
            id: "roleAccess",
            Header: "Access",
            accessor: (a) =>
              a.rolesConsole && a.rolesConsole.roleAccess
                ? roles?.filter(
                    (role) => role.id === a.rolesConsole.roleAccess
                  )[0].state
                : "None",
            width: 73,
          },
          {
            id: "roleAudio",
            Header: "Audio",
            accessor: (a) =>
              a.rolesConsole && a.rolesConsole.roleAudio
                ? roles?.filter(
                    (role) => role.id === a.rolesConsole.roleAudio
                  )[0].state
                : "None",
            width: 73,
          },
          {
            id: "roleVideo",
            Header: "Video",
            accessor: (a) =>
              a.rolesConsole && a.rolesConsole.roleVideo
                ? roles?.filter(
                    (role) => role.id === a.rolesConsole.roleVideo
                  )[0].state
                : "None",
            width: 73,
          },
          {
            id: "roleMy",
            Header: "My Role",
            accessor: (a) => (a.roles ? a.roles[0] : "None"),
            width: 73,
          },
        ],
      },
    ];
    return permissions2("5cade6648d3306167367505b", methods) ? (
      <div className={classes.root}>
        <h2 style={{ marginLeft: "12px", paddingTop: "12px" }}>
          {this.props.match.route.name}
        </h2>
        <div className={classes.horizontalContainer}>
          <ReactTable
            data={fullData}
            columns={tableColumns}
            defaultPageSize={pageSize}
            showPagination={fullData.length > pageSize}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id])
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            }
            className="-striped -highlight"
            style={{
              display: "block",
              margin: "8px 0px 8px 0px",
              fontSize: "11px",
              flex: "auto",
              minWidth: "25vw",
            }}
            getTrProps={(state, rowInfo) => {
              const selectedId = rowInfo ? rowInfo.original.id : null;
              return {
                onClick: () => this.selectRow(rowInfo.original.id),
                style: {
                  background: selectedId === selectedRow ? openRow : "#FFFFFF",
                },
              };
            }}
            getTdProps={(state, rowInfo /* , column, instance */) => ({
              onClick: (e, handleOriginal) => {
                e.preventDefault();
                rowInfo && this.handleControlToggle(rowInfo.original);
                handleOriginal && handleOriginal();
              },
            })}
          />
          {userControlId !== "none" && userControlId ? (
            <UsersControl
              viewer={viewer}
              user={userControl}
              toggleClose={this.toggleClose}
            />
          ) : null}
        </div>
        <Typography
          variant="caption"
          color="secondary"
          className={classes.typo}
          style={{ paddingLeft: 8 }}
          align="left"
        >
          Tip: Hold shift when sorting to multi-sort!
        </Typography>
      </div>
    ) : null;
  }
}

Users.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, //eslint-disable-line
};

export default withStyles(styles)(
  createFragmentContainer(Users, {
    viewer: graphql`
      fragment Users_viewer on Viewer {
        allClients {
          edges {
            node {
              id
              name
            }
          }
        }
        allVideoClients {
          edges {
            node {
              id
              name
            }
          }
        }
        allAudioDevices {
          edges {
            node {
              id
              isPlaying
              isOnline
              publicIpAddress
              name
              clientId
              usersId {
                id
              }
              deviceStatus
            }
          }
        }
        allUsers {
          edges {
            node {
              id
              roles
              createdAt
              apiKey
              services {
                password {
                  bcrypt
                }
                google {
                  accessToken
                  idToken
                  id
                  email
                  name
                  given_name
                  family_name
                  picture
                }
                facebook {
                  accessToken
                  id
                  email
                  name
                  first_name
                  last_name
                  link
                  gender
                  locale
                  verified
                }
              }
              emails {
                address
                verified
              }
              profile {
                name
                clientId
                videoClientId
                sonosPermit
                qsysPermit
              }
              rolesConsole {
                roleAccess
                roleAudio
                roleVideo
              }
              sonos {
                songCount
                lastPlayedTimestamp
                extensionMenuId
              }
              qsys {
                pin
                multiTenant
                globalZoneLimit
              }
              serviceType
              defaultServiceType
              status {
                online
                lastLogin {
                  date
                  ipAddr
                }
              }
              companyName
              serviceType
              defaultServiceType
              qsysData {
                qsysDeviceIds
                noOfCores
                noOfZones
              }
            }
          }
        }
        allSonosMenu {
          id
          name
          description
          clientId
          childrenMenus {
            id
            name
            description
          }
        }
        user {
          id
          consoleSettings {
            users {
              details
              actions
              permissions
              logs
            }
          }
          roles
          companyName
          rolesConsole {
            roleAccess
            roleAudio
            roleVideo
          }
        }
      }
    `,
  })
);

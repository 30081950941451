import React, { Component } from "react";
import { createRefetchContainer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ReactTable from "react-table";
import * as XLSX from "xlsx";
import "react-table/react-table.css";
import jwt from "jsonwebtoken";
import moment from "moment";
import TextField from "@material-ui/core/TextField";

import SM from "../subscriptions/index";
import AudioDevicesFilter from "./AudioDevicesFilter";
import AudioDeviceControl from "./AudioDeviceControl";
import permissions from "../authentication/isPermited";
import permissions2 from "../authentication/UIPermissions";
import offlineImage from "../../media/activaire-red.png";
import onlineImage from "../../media/activaire-green.png";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import ClientModal from "../0_components/ClientModal";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "0px 0px 0px 0px",
  },
  typo: {
    padding: 0,
  },
  typoClient: {
    padding: "0px 0px 0px 0px",
    color: "secondary",
  },
  button: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    marginLeft: "9px",
    "&:hover": {
      background: "#e3fd59",
    },
    minWidth: 150,
    margin: 5,
    marginBottom: 9,
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  horizontalContainer2: {
    display: "flex",
    flexWrap: "nowrap",
  },
};

class AudioDevices extends Component {
  constructor(props) {
    super(props);

    const allTagsFiltered = props.viewer.allTags.edges.filter(
      (item) => item && item.node
    );

    const allColumns = [
      {
        Header: "App UP time",
        accessor: "appUpTime",
        maxWidth: 100,
      },
      {
        id: "playmode",
        Header: "PlayMode",
        accessor: (a) => (a.remotePlaylistMode ? "Manual" : "Auto"),
        maxWidth: "70",
      },
      {
        Header: "Current Playlist",
        accessor: "currentPlaylist",
        maxWidth: 200,
      },
      {
        Header: "Make and model",
        accessor: "makeAndModel",
        maxWidth: 200,
      },
      {
        Header: "MAC Adress",
        accessor: "ethernetMacAddress",
        maxWidth: 200,
      },
      {
        id: "appVersion",
        Header: "App Version",
        accessor: (a) => a.appVersionDS || a.appVersion,
        maxWidth: 100,
      },
      {
        Header: "Volume",
        accessor: "volume",
        maxWidth: 100,
      },
      {
        id: "tags",
        Header: "Device Tags",
        accessor: (a) =>
          a.tags
            ? a.tags
                .map(
                  (tag) =>
                    allTagsFiltered.filter((item) => item.node.id === tag)[0]
                      .node.name
                )
                .join(", ")
            : null,
        maxWidth: 200,
      },
    ];
    const KPIs = [
      {
        Header: "KPI 1",
        accessor: "kpi1",
        maxWidth: 100,
        disabled: true,
      },
      {
        Header: "KPI 3",
        accessor: "kpi3",
        maxWidth: 100,
        disabled: true,
      },
      {
        Header: "KPI 30",
        accessor: "kpi30",
        maxWidth: 100,
        disabled: true,
      },
    ];

    this.state = {
      pendingValue: null,
      left: false,
      clientId: localStorage.getItem("audioClientId"),
      user: jwt.decode(localStorage.getItem("authentication_token")),
      anchorEl: null,
      deviceControlId: "none",
      deviceControl: {},
      selectedRow: "",
      allColumns: permissions([1]) ? allColumns.concat(KPIs) : allColumns,
      addedColumns: [],
    };
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.myCallBack = this.myCallBack.bind(this);
    this.handleAddColumsMenu = this.handleAddColumsMenu.bind(this);
    this.handleAddColumns = this.handleAddColumns.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.myCallBack2 = this.myCallBack2.bind(this);
    this.handleControlToggle = this.handleControlToggle.bind(this);
    this.toggleClose = this.toggleClose.bind(this);
    this.selectRow = this.selectRow.bind(this);
    this.handleExportSelection = this.handleExportSelection.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.match.location.pathname === window.location.pathname) {
      if (this.props.deviceControlId !== nextProps.deviceControlId) {
        const deviceControl = nextProps.viewer.allAudioDevices.edges
          .filter((item) => item && item.node)
          .filter((item) => item.node.deviceStatus === "ACTIVE")
          .filter((data) =>
            data.node.id === this.state.deviceControlId ? data.node : null
          );

        if (deviceControl[0]) {
          this.setState({
            deviceControl: deviceControl[0].node,
          });
        } else {
          this.setState({
            deviceControl: {},
            deviceControlId: "none",
          });
        }
      }
      return true;
    }
    return false;
  }

  componentDidMount() {
    const loginProcedure = async () => {
      if (SM.token) {
        this.subscription1 = await SM?.UserSubscription(SM?.token);
        this.subscription2 = await SM?.TagSubscription(SM?.token);
        this.subscription3 = await SM?.PlaylistSubscription(SM?.token);
        this.subscription4 = await SM?.SceneSubscription(SM?.token);
      }
    };

    loginProcedure();
  }

  async componentWillUnmount() {
    try {
      await this.subscription1?.dispose();
      await this.subscription2?.dispose();
      await this.subscription3?.dispose();
      await this.subscription4?.dispose();
    } catch (e) {
      console.log("componentWillUnmount AudioDevices Error:", e);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.deviceControlId !== "none") {
      const deviceControl = nextProps.viewer.allAudioDevices.edges
        .filter((item) => item && item.node)
        .filter((item) => item.node.deviceStatus === "ACTIVE")
        .filter((data) =>
          data.node.id === this.state.deviceControlId ? data.node : null
        );
      if (deviceControl[0]) {
        this.setState({
          deviceControl: deviceControl[0].node,
        });
      } else {
        this.setState({
          deviceControl: {},
          deviceControlId: "none",
        });
      }
    }
  }

  selectRow(deviceId) {
    this.setState({
      selectedRow: deviceId,
    });
    this.setState({
      deviceControlId: deviceId,
    });
  }

  handleControlToggle(device) {
    this.setState({
      deviceControlId: device.id,
      deviceControl: device,
    });
  }

  toggleClose() {
    this.setState({
      deviceControlId: "none",
    });
  }

  myCallBack(data) {
    this.setState({
      clientId: data,
    });
    return data;
  }

  toggleDrawer(open) {
    return this.setState({
      left: open,
    });
  }

  handleAddColumsMenu(e) {
    this.setState({
      anchorEl: e.currentTarget,
    });
  }

  handleAddColumns(column, index) {
    const { allColumns, addedColumns } = this.state;
    addedColumns.push(column);
    allColumns.splice(index, 1);
    this.setState({
      addedColumns,
      allColumns,
    });
    if (!allColumns[0]) {
      this.setState({
        anchorEl: null,
      });
    }
  }

  handleClose() {
    this.setState({
      anchorEl: null,
    });
  }

  myCallBack2(column, index) {
    const { allColumns, addedColumns } = this.state;
    allColumns.push(column);
    addedColumns.splice(index, 1);
    this.setState({
      addedColumns,
      allColumns,
    });
  }

  handleExportSelection(rawData) {
    let data = [...(rawData || [])];
    if ([2, 3].includes(this.state?.user?.roleAccess)) {
      // if the user's role is 2 or 3, we need to deliver only a part of the keys from the data array objects:
      // name, isPlaying, volume, isOnline, remotePlaylistMode(renamed to offSchedule)
      data = data.map((item) => ({
        name: item.name,
        isPlaying: item.isPlaying,
        volume: item.volume,
        isOnline: item.isOnline,
        offSchedule: item.remotePlaylistMode,
      }));
    }
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    ws["!cols"] = [];
    const n = Object.keys(data[0]).length;
    for (let i = 0; i < n; i += 1) {
      ws["!cols"][i] = { width: 22 };
    }
    XLSX.utils.book_append_sheet(wb, ws, "LogData");
    XLSX.writeFile(wb, `AudioDeviceExport--${moment().format()}.xlsx`);
  }

  render() {
    const { classes, viewer, methods, match } = this.props;
    const {
      anchorEl,
      clientId,
      allColumns,
      addedColumns,
      deviceControl,
      deviceControlId,
      selectedRow,
      pendingValue,
    } = this.state;

    const allScheduleFiltered = viewer.allSchedule.edges.filter(
      (item) => item && item.node
    );

    const fixedColumns = [
      {
        id: "isOnline",
        Header: "Online (y/n)",
        accessor: (a) => (a.isOnline ? "Yes" : "No"),
        maxWidth: 70,
        Cell: (props) => {
          if (props.original.isOnline) {
            return (
              <img
                style={{ cursor: "pointer" }}
                width="30"
                height="30"
                src={onlineImage}
                alt=""
              />
            );
          } else {
            return (
              <img
                style={{ cursor: "pointer" }}
                width="30"
                height="30"
                src={offlineImage}
                alt=""
              />
            );
          }
        },
      },
      {
        id: "isPlaying",
        Header: "Playing (y/n)",
        accessor: (a) => (a.isPlaying ? "Yes" : "No"),
        maxWidth: 70,
        Cell: (props) =>
          props.original.isPlaying ? (
            <span style={{ cursor: "pointer" }}>
              <PlayArrowIcon className={classes.icon} />
            </span>
          ) : (
            <span style={{ cursor: "pointer" }}>
              <PauseIcon className={classes.icon} />
            </span>
          ),
      },
      {
        Header: "Name",
        accessor: "name",
        maxWidth: 400,
      },
      {
        id: "appStatus",
        Header: "Status",
        accessor: (a) => (a.appStatus ? a.appStatus.status : ""),
        maxWidth: 200,
      },
      {
        Header: "Last Sync",
        accessor: "lastSyncTime",
        maxWidth: 200,
      },
      {
        id: "scheduleIds",
        Header: "Device Schedule",
        accessor: (a) =>
          a.scheduleIds
            ? a.scheduleIds
                .map((schedule) => {
                  const filteredSch = allScheduleFiltered.filter(
                    (item) => item.node && item.node.id === schedule
                  )[0];
                  return filteredSch ? filteredSch.node.name : "";
                })
                .join(", ")
            : null,
        maxWidth: 200,
      },
    ];

    const allPlaylists = viewer.allPlaylists.edges
      .filter((item) => item && item.node)
      .map(({ node }) => node);

    const allScenes = viewer.allScenes.edges
      .filter((item) => item && item.node)
      .map(({ node }) => node);

    const fullData = viewer.allAudioDevices.edges
      .filter((item) => item && item.node)
      .filter((item) => item.node.deviceStatus === "ACTIVE")
      .map(({ node }) => node)
      .map((newItem) => ({
        ...newItem,
        kpi1: Number(newItem["kpi1"]).toFixed(0),
        kpi3: Number(newItem["kpi30"]).toFixed(0),
        kpi30: Number(newItem["kpi30"]).toFixed(0),
        currentPlaylist: [...allPlaylists, ...allScenes].find(
          (item) =>
            item.id === newItem?.currentPlaylistId ||
            item.id === newItem.currentSceneId
        )?.name,
      }));

    const openRow = "#DCDCDC";

    const filteredData = fullData.filter((node) => node.clientId === clientId);

    const searchData = (clientParam) => {
      if (clientParam !== "ALL") {
        const devices = filteredData.filter((item) => {
          if (
            JSON.stringify(item)
              .toLowerCase()
              .indexOf(String(pendingValue).toLowerCase()) > -1
          ) {
            return true;
          } else {
            return false;
          }
        });
        return devices;
      } else {
        const devices = fullData.filter((item) => {
          if (
            JSON.stringify(item)
              .toLowerCase()
              .indexOf(String(pendingValue).toLowerCase()) > -1
          ) {
            return true;
          } else {
            return false;
          }
        });
        return devices;
      }
    };

    const deviceIds = filteredData.map((device) => ({
      deviceId_contains: device.id,
    }));

    let currentClient = "ALL";
    try {
      if (clientId !== "ALL") {
        const exists = viewer.allClients.edges.find(
          (item) => item?.node?.id === clientId
        );

        if (exists?.node) {
          currentClient = exists.node.name;
        }
      }
    } catch (e) {
      console.log("Current client error: ", e);
    }

    const data = clientId === "ALL" ? fullData : filteredData;
    const pageSize = 50;

    localStorage.setItem("audioClientId", clientId);

    return permissions2("5cac9d063630643c2a423815", methods) ? (
      <>
        <div className={classes.root}>
          <ClientModal
            match={match}
            clientId={clientId}
            clientName={currentClient}
            open={this.state.left}
            onClose={() => this.toggleDrawer(false)}
            classes={classes}
            onClick={() =>
              this.setState({
                clientId: "ALL",
              })
            }
            viewer={viewer}
            myCallBack={this.myCallBack}
          />
          <div
            className={classes.horizontalContainer}
            style={{
              display: "flex",
              height: "60px",
              flexDirection: "row",
              alignItems: "center",
              marginLeft: "12px",
            }}
          >
            {permissions2("5cab0ae7d35d5905be019916", methods) && (
              <>
                <span
                  style={{
                    cursor: "pointer",
                    color: "#3BABA1",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                  onClick={() => this.toggleDrawer(true)}
                >
                  Change Client &nbsp;
                </span>{" "}
                |
              </>
            )}
            {permissions2("5cab0ae7d35d5905be019917", methods) && (
              <>
                <span
                  style={{
                    cursor: "pointer",
                    color: "#3BABA1",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                  onClick={() => this.handleExportSelection(data)}
                >
                  &nbsp;Export &nbsp;
                </span>{" "}
                |
              </>
            )}
            {permissions2("5cab2e9595a3bc0a8a1ae919", methods) &&
            allColumns[0] ? (
              <>
                <span
                  style={{
                    cursor: "pointer",
                    color: "#3BABA1",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                  onClick={this.handleAddColumsMenu}
                >
                  &nbsp;Add Columns &nbsp;
                </span>{" "}
                |
              </>
            ) : null}
            <TextField
              onChange={(e) => {
                this.setState({ pendingValue: e.target.value });
              }}
              label="Search player"
              variant="outlined"
              style={{
                marginLeft: "12px",
              }}
            />
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
            >
              {allColumns.map((column, index) => (
                <MenuItem
                  disabled={column.disabled}
                  key={index}
                  onClick={() => this.handleAddColumns(column, index)}
                >
                  {`${column.Header}`}
                </MenuItem>
              ))}
            </Menu>
            <AudioDevicesFilter
              addedColumns={addedColumns}
              callback={this.myCallBack2}
            />
          </div>
          <div className={classes.horizontalContainer2}>
            <ReactTable
              data={pendingValue ? searchData(currentClient) : data}
              columns={fixedColumns.concat(addedColumns)}
              defaultPageSize={pageSize}
              showPagination={data.length > pageSize}
              filterable
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id])
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              }
              className="-striped -highlight"
              style={{
                margin: "8px 0px 8px 8px",
                fontSize: "13px",
                height: "100vh",
                width: "100%",
              }}
              getTrProps={(state, rowInfo) => {
                const selectedId = rowInfo ? rowInfo.original.id : null;
                return {
                  onClick: () => this.selectRow(rowInfo.original.id),
                  style: {
                    background:
                      selectedId === selectedRow ? openRow : "#FFFFFF",
                  },
                };
              }}
              getTdProps={(state, rowInfo /* , column, instance */) => ({
                onClick: (e, handleOriginal) => {
                  e.preventDefault();
                  rowInfo && this.handleControlToggle(rowInfo.original);
                  handleOriginal && handleOriginal();
                },
              })}
            />
            {deviceControlId !== "none" && deviceControlId ? (
              <AudioDeviceControl
                clientId={clientId}
                viewer={viewer}
                deviceControlId={deviceControlId}
                deviceControl={deviceControl}
                toggleClose={this.toggleClose}
                deviceIds={deviceIds}
                methods={methods}
              />
            ) : null}
          </div>
          <Typography
            variant="caption"
            color="secondary"
            className={classes.typo}
            style={{
              paddingLeft: 8,
            }}
            align="left"
          >
            Tip: Hold shift when sorting to multi - sort!
          </Typography>
        </div>
      </>
    ) : null;
  }
}

AudioDevices.propTypes = {
  classes: PropTypes.object.isRequired,
  viewer: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  createRefetchContainer(AudioDevices, {
    viewer: graphql`
      fragment AudioDevices_viewer on Viewer {
        allNPLogs {
          edges {
            node {
              id
              date
              deviceId
            }
          }
        }
        allPCLogs {
          edges {
            node {
              id
              date
              deviceId
            }
          }
        }
        allTags {
          edges {
            node {
              id
              name
              description
            }
          }
        }
        allClients {
          edges {
            node {
              id
              name
              playlistIds
              sceneIds
              logo
            }
          }
        }
        allSchedule {
          edges {
            node {
              id
              name
              injectable
              clientId
              startDate
              endDate
            }
          }
        }
        allPlaylists {
          edges {
            node {
              id
              name
              clientsId
            }
          }
        }
        allUsers {
          edges {
            node {
              id
              profile {
                name
                clientId
                clientName
              }
              emails {
                address
              }
            }
          }
        }
        allAudioDevices {
          edges {
            node {
              id
              isPlaying
              name
              appStatus {
                status
                message
                time
                description
              }
              lastSyncTime
              clientId
              appUpTime
              currentM3uName
              appVersion
              uid
              description
              volume
              remoteVolume
              remoteVolumeMode
              requestPCLogs
              requestNPLogs
              lastOnline
              currentPlaylistId
              currentSceneId
              currentSongId
              currentAlbum
              currentArtist
              currentArtwork
              remotePlaylistMode
              isOnline
              currentMd5
              currentSongFilename
              totalSdStorage
              usedSdStorage
              availableSdStorage
              m3uFilesOnDevice
              songFilesOnDevice
              latitude
              longitude
              skipCount
              skipDisabled
              likedSong
              scheduleIds
              usersId {
                id
                profile {
                  name
                  clientId
                  clientName
                }
                emails {
                  address
                }
              }
              playlistsId
              playbackIds
              tags
              enrollmentDate
              deviceStatus
              makeAndModel
              macAddress
              ethernetMacAddress
              internalIpAddress
              publicIpAddress
              webkeyId
              webkeyBackupId
              appVersionDS
              kpi1
              kpi3
              kpi30
              crossfade
              maximumDaysOffline
              maximumSongsOffline
              serialNumber
            }
          }
        }
        user {
          id
          consoleSettings {
            audioDevices {
              appDetails
              logs
              nowPlaying
              systemDetailsControls
              user
              tags
            }
          }
        }
        allScenes {
          edges {
            node {
              id
              name
              description
              image
              size
              createdAt
              playlists {
                playlistId
              }
            }
          }
        }
      }
    `,
  })
);

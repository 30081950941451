import React, { useState } from "react";
import ReactTable from "react-table";
import { withStyles } from "@material-ui/core/styles";
import { useLazyLoadQuery } from "react-relay";
import { ALL_QSYS_DEVICES } from "../queries/allQsysDevices";
import QsysDeviceControl from "./qsysDeviceControl";

const styles = {
  horizontalContainer: {
    display: "flex",
    flexDirection: "column",
  },
};

const caseInsensitiveFilter = (filter, row) => {
  const searchTerm = (filter.value || "").toLowerCase();
  const content = String(row[filter.id] || "").toLowerCase();
  return content.includes(searchTerm);
};

const pageSize = 100;
const columns = [
  {
    Header: "Device Id",
    accessor: "deviceId",
    width: 200,
    filterMethod: caseInsensitiveFilter,
  },
  {
    Header: "Name",
    accessor: "name",
    width: 200,
    filterMethod: caseInsensitiveFilter,
  },
  {
    id: "clientName",
    Header: "Client",
    accessor: (a) => a.client?.name || "None",
    width: 100,
    filterMethod: caseInsensitiveFilter,
  },
  {
    Header: "System Version",
    accessor: "systemVersion",
    width: 120,
    filterMethod: caseInsensitiveFilter,
  },
  {
    Header: "IP Address",
    accessor: "ipAddress",
    width: 110,
    filterMethod: caseInsensitiveFilter,
  },
  {
    Header: "Country",
    accessor: "countryCode",
    width: 80,
    filterMethod: caseInsensitiveFilter,
  },
  {
    Header: "Zone Cap",
    accessor: "noOfZones",
    width: 70,
    filterMethod: caseInsensitiveFilter,
  },
  {
    id: "activeZones",
    Header: "Active Zones",
    accessor: (a) => a.zones?.length || 0,
    width: 100,
    filterMethod: caseInsensitiveFilter,
  },
  {
    id: "userEmail",
    Header: "User Email",
    accessor: (a) => a.currentUser?.emails[0].address,
    width: 200,
    filterMethod: caseInsensitiveFilter,
  },
  {
    id: "userName",
    Header: "User Name",
    accessor: (a) => a.currentUser?.profile?.name || "No Name",
    width: 150,
    filterMethod: caseInsensitiveFilter,
  },
];

const QsysDeviceTable = (props) => {
  const { classes } = props;
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);

  const { viewer } = useLazyLoadQuery(ALL_QSYS_DEVICES, {
    fetchPolicy: "store-and-network",
  });

  const allQsysDevices = viewer?.allQsysDevices?.filter((item) => item) || [];
  const selectedDevice = allQsysDevices.find(
    (item) => item.id === selectedDeviceId
  );

  return (
    <div className={classes.horizontalContainer}>
      <ReactTable
        data={allQsysDevices}
        defaultPageSize={pageSize}
        showPagination={allQsysDevices.length > pageSize}
        filterable
        columns={columns}
        style={{
          margin: "8px 0px 8px 8px",
          fontSize: "13px",
          height: "100vh",
          width: "100%",
          display: "grid",
        }}
        getTrProps={(state, rowInfo) => {
          const selectedId = rowInfo?.original?.id;
          return {
            onClick: () =>
              rowInfo?.original?.id && setSelectedDeviceId(rowInfo.original.id),
            style: {
              background:
                selectedId === selectedDevice?.id ? "#DCDCDC" : "#FFFFFF",
            },
          };
        }}
      />
      {selectedDeviceId && selectedDevice && (
        <QsysDeviceControl
          device={selectedDevice}
          onClose={() => setSelectedDeviceId(null)}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(QsysDeviceTable);

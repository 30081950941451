import React, { useEffect } from "react";
import Draggable from "react-draggable";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import Fade from "@material-ui/core/Fade";
import ClearIcon from "@material-ui/icons/Clear";
import deleteQsysDeviceMutation from "../../mutations/deleteQsysDeviceMutation";
import EditIcon from "@material-ui/icons/Edit";
import ChangeNameModal from "./changeNameModal";
import DeviceControlDetails from "./deviceControlDetails";
import DeviceControlZones from "./deviceControlZones";

const styles = {
  paper: {
    background: "#c4c4c4",
    top: "150px",
    right: "90px",
    bottom: "0px",
    position: "fixed",
    overflow: "hidden scroll",
    maxWidth: "50vw",
    zIndex: 100,
    padding: "0px 8px 8px 8px",
    margin: "8px 8px 8px 8px",
    flex: "auto",
    minWidth: "30vw",
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "nowrap",
  },
  close: {
    position: "relative",
    marginTop: 6,
  },
};

const QsysDeviceControl = (props) => {
  const { classes, onClose, device } = props;
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [editNameModal, setEditNameModal] = React.useState(false);

  return (
    <Draggable handle=".handle">
      <Paper className={classes.paper} elevation={1}>
        <div
          className={classes.horizontalContainer}
          style={{
            position: "sticky",
            zIndex: 9999999,
            backgroundColor: "#c4c4c4",
            top: 0,
          }}
        >
          <div
            className={classes.horizontalContainer}
            style={{
              flex: "2",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div>
              <Typography
                className={classes.typo2}
                variant="body1"
                color="textPrimary"
              >
                {device?.name || "No Name"}
              </Typography>
              <Typography
                className={classes.typo2}
                variant="body1"
                color="textSecondary"
              >
                {device?.description || ""}
              </Typography>
            </div>
          </div>
          <div
            className={classes.horizontalContainer}
            style={{ flex: "1", justifyContent: "flex-end" }}
          >
            <div className={classes.close}>
              <div className="handle">
                <IconButton onClick={() => ""} color="primary">
                  <DragIndicatorIcon style={{ color: "#000" }} />
                </IconButton>
              </div>
            </div>
            {!deleteModal ? (
              <div className={classes.close}>
                <IconButton
                  color="primary"
                  onClick={() => setDeleteModal(true)}
                >
                  <Tooltip
                    id="tooltip-bottom"
                    title="Delete"
                    placement="bottom"
                    enterTouchDelay={200}
                  >
                    <DeleteIcon color="primary" className={classes.closeIcon} />
                  </Tooltip>
                </IconButton>
              </div>
            ) : (
              <Fade in={deleteModal} timeout={250}>
                <div className={classes.horizontalContainer}>
                  <div className={classes.close}>
                    <Typography
                      style={{
                        width: "100px",
                        paddingTop: "10px",
                        paddingLeft: "15px",
                      }}
                      className={classes.typo3}
                      variant="subtitle1"
                      color="primary"
                      align="center"
                    >
                      Are you sure?
                    </Typography>
                  </div>
                  <div className={classes.close}>
                    <Tooltip
                      id="tooltip-bottom"
                      title="Cancel Delete"
                      placement="bottom"
                      enterTouchDelay={200}
                    >
                      <IconButton
                        color="primary"
                        onClick={() => setDeleteModal(false)}
                      >
                        <ClearIcon
                          color="primary"
                          className={classes.closeIcon}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div className={classes.close}>
                    <Tooltip
                      id="tooltip-bottom"
                      title="Delete Player"
                      placement="bottom"
                      enterTouchDelay={200}
                    >
                      <IconButton
                        color="primary"
                        onClick={() =>
                          deleteQsysDeviceMutation(device.id, () => onClose())
                        }
                      >
                        <DeleteIcon
                          color="primary"
                          className={classes.closeIcon}
                          style={{ color: "tomato" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </Fade>
            )}
            <div className={classes.close}>
              <IconButton
                onClick={() => setEditNameModal(true)}
                color="primary"
              >
                <Tooltip
                  id="tooltip-bottom"
                  title="Change Device Name"
                  placement="bottom"
                  enterTouchDelay={200}
                >
                  <EditIcon className={classes.closeIcon} />
                </Tooltip>
              </IconButton>
            </div>
            <div className={classes.close}>
              <Tooltip
                id="tooltip-bottom"
                title="Close"
                placement="bottom"
                enterTouchDelay={200}
              >
                <IconButton
                  aria-label="Close"
                  onClick={() => onClose()}
                  color="primary"
                >
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
        <DeviceControlDetails device={device} />
        <DeviceControlZones device={device} />
        {editNameModal && (
          <ChangeNameModal
            device={device}
            open={editNameModal}
            close={() => setEditNameModal(false)}
          />
        )}
      </Paper>
    </Draggable>
  );
};

export default withStyles(styles)(QsysDeviceControl);

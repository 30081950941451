/**
 * @flow
 * @relayHash b9600b5879f320f5ef93ba3f653874da
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MixType = "playlist" | "scene" | "%future added value";
export type ZoneReason = "pause" | "play" | "skip" | "%future added value";
export type editQsysDeviceNameMutationVariables = {|
  deviceId: string,
  name: string,
  description: string,
|};
export type editQsysDeviceNameMutationResponse = {|
  +editQsysDeviceName: ?{|
    +id: string,
    +name: ?string,
    +description: ?string,
    +dateCreated: string,
    +currentLoginToken: ?string,
    +deviceId: string,
    +systemVersion: ?string,
    +systemIsEmulating: ?boolean,
    +coreUptime: ?number,
    +coreDate: ?any,
    +coreTime: ?number,
    +ipAddress: ?string,
    +totalStorage: ?number,
    +availableStorage: ?number,
    +currentUser: ?{|
      +id: string,
      +profile: ?{|
        +name: ?string,
        +clientId: ?string,
      |},
      +emails: ?$ReadOnlyArray<?{|
        +address: ?string
      |}>,
    |},
    +countryCode: ?string,
    +userIds: ?$ReadOnlyArray<?{|
      +id: string,
      +profile: ?{|
        +name: ?string
      |},
      +emails: ?$ReadOnlyArray<?{|
        +address: ?string
      |}>,
    |}>,
    +noOfZones: ?number,
    +lastHeartbeat: ?string,
    +client: ?{|
      +id: string,
      +name: ?string,
    |},
    +zones: ?$ReadOnlyArray<?{|
      +zoneId: number,
      +zoneName: string,
      +songCount: ?number,
      +songId: ?string,
      +currentAlbum: ?string,
      +currentTitle: ?string,
      +currentArtist: ?string,
      +currentArtwork: ?string,
      +reason: ?ZoneReason,
      +positionMillis: ?number,
      +durationMillis: ?number,
      +isScheduleActive: ?boolean,
      +schedule: ?string,
      +updateTrigger: ?boolean,
      +songReaction: ?number,
      +mixType: ?MixType,
      +mixId: ?string,
      +mixName: ?string,
      +volume: ?number,
    |}>,
  |}
|};
export type editQsysDeviceNameMutation = {|
  variables: editQsysDeviceNameMutationVariables,
  response: editQsysDeviceNameMutationResponse,
|};
*/


/*
mutation editQsysDeviceNameMutation(
  $deviceId: String!
  $name: String!
  $description: String!
) {
  editQsysDeviceName(deviceId: $deviceId, name: $name, description: $description) {
    id
    name
    description
    dateCreated
    currentLoginToken
    deviceId
    systemVersion
    systemIsEmulating
    coreUptime
    coreDate
    coreTime
    ipAddress
    totalStorage
    availableStorage
    currentUser {
      id
      profile {
        name
        clientId
      }
      emails {
        address
      }
    }
    countryCode
    userIds {
      id
      profile {
        name
      }
      emails {
        address
      }
    }
    noOfZones
    lastHeartbeat
    client {
      id
      name
    }
    zones {
      zoneId
      zoneName
      songCount
      songId
      currentAlbum
      currentTitle
      currentArtist
      currentArtwork
      reason
      positionMillis
      durationMillis
      isScheduleActive
      schedule
      updateTrigger
      songReaction
      mixType
      mixId
      mixName
      volume
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "description"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deviceId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "UserEmails",
  "kind": "LinkedField",
  "name": "emails",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "description",
        "variableName": "description"
      },
      {
        "kind": "Variable",
        "name": "deviceId",
        "variableName": "deviceId"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      }
    ],
    "concreteType": "QsysDevice",
    "kind": "LinkedField",
    "name": "editQsysDeviceName",
    "plural": false,
    "selections": [
      (v3/*: any*/),
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dateCreated",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currentLoginToken",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deviceId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "systemVersion",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "systemIsEmulating",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "coreUptime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "coreDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "coreTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ipAddress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalStorage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "availableStorage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfile",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientId",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "countryCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "userIds",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfile",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "noOfZones",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastHeartbeat",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AudioClient",
        "kind": "LinkedField",
        "name": "client",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Zone",
        "kind": "LinkedField",
        "name": "zones",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "zoneId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "zoneName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "songCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "songId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentAlbum",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentTitle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentArtist",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentArtwork",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reason",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "positionMillis",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "durationMillis",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isScheduleActive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "schedule",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updateTrigger",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "songReaction",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mixType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mixId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mixName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "volume",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "editQsysDeviceNameMutation",
    "selections": (v6/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "editQsysDeviceNameMutation",
    "selections": (v6/*: any*/)
  },
  "params": {
    "id": "b9600b5879f320f5ef93ba3f653874da",
    "metadata": {},
    "name": "editQsysDeviceNameMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '61f9a5cbe7397213c101712dafcaca4e';

module.exports = node;
